import React from 'react'
import { Link } from 'gatsby'

import { ButtonLink } from '../components/ButtonLink'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not found" />
    <div className="text-center py-32 xl:py-48">
      <h1 className="text-4xl mb-4">Page Not Found</h1>
      <ButtonLink to="/">Go home</ButtonLink>
    </div>
  </Layout>
)

export default NotFoundPage
